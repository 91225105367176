const getters = {
  sidebarCollapse: state => state.app.sidebarCollapse,
  device: state => state.app.device,
  userData: state => state.user.userData,
  menus: state => state.user.menus,
  permissionFlags: state => state.user.permissionFlags,
  rightMenu: state => state.user.rightMenu,
  isShowRightMenu: state => state.app.sidebarCollapse,
  notify: state => state.notify,
}
export default getters
