import Vue from 'vue';
import dialog from './preview.vue'

const VueExtend = Vue.extend(dialog);
let vm = null;

export function preview(src, index = 0) {
  if (!vm) {
    vm = new VueExtend();
  }
  if (Array.isArray(src) && src.length == 1) {
    vm.src = src[0];
  } else {
    vm.src = src;
  }
  if (index !== undefined && index > -1) {
    vm.initialIndex = index;
  }
  if (!vm._isMounted) {
    let el = vm.$mount().$el;
    document.body.appendChild(el);
  }
  vm.open();
}
export default {
  inserted(el, binding, vnode) {
    let src = el.src || binding.value;
    if (!src) {
      el.style.display = 'none';
      return;
    }
    el.src = src;
    el.style.cssText += ';cursor:pointer;'
    el.previewHandler = () => {
      preview(src);
    };
    el.addEventListener('click', el.previewHandler);
  },
  componentUpdated(el, binding) {
    let src = el.src || binding.value;
    if (!src) {
      el.style.display = 'none';
      return;
    }
    el.src = src;
    el.style.cssText += ';cursor:pointer;'
    if (el.previewHandler) {
      el.removeEventListener('click', el.previewHandler);
    }
    el.previewHandler = () => {
      preview(src);
    };
    el.addEventListener('click', el.previewHandler);
  },
  unbind(el) {
    el.removeEventListener('click', el.previewHandler);
  }
}
