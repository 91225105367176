import { Notification } from 'element-ui';

export function notify({ title, message, ...options }) {
  Notification({
    title,
    message,
    dangerouslyUseHTMLString: true,
    type: "info",
    duration: 0,
    ...options,
  })
}
