/**
 * @param {string} path
 * @returns {Boolean}
 */
export function isExternal(path) {
  return /^(https?:|mailto:|tel:)/.test(path)
}


/**
 * @param option
  {
        name：String   检验规则
        msg: String    错误信息
    }
 * @returns {Function}
 */
export function validateParams(option) {
  return function(rule, value, callback) {
    strategies['isValidate' + option.type](rule, value, callback, option);
  };
}

const strategies = {
  /* 验证长度 */
  isValidateLength(rule, value, callback, option) {
    if (value.length < option.min) {
      callback(new Error(option.msg));
    } else if (value.length > option.max) {
      callback(new Error(option.msg));
    } else {
      callback();
    }
  },

  /* 验证密码（包含数字、字母、特殊字符(不包括空格)） */
  isValidatePassword(rule, value, callback, option) {
    let regFormat = /^(?=.*\d)(?=.*[a-zA-Z])(?=.*[\W_]).{8,}$/;
    if (!(regFormat.test(value)) || /\s/.test(value)) {
      callback(new Error(option.msg));
    } else {
      callback();
    }
  },

  /* 验证邮箱 */
  isValidateEmail(rule, value, callback, option) {
    let regFormat = /^\w+([-+.]\w+)*@\w+([-.]\w+)*\.\w+([-.]\w+)*$/; // 正确邮箱
    if (!(regFormat.test(value))) {
      callback(new Error(option.msg));
    } else {
      callback();
    }
  },

  /* 验证url */
  isValidateUrl(rule, value, callback, option) {
    let regFormat = /(https?|ftp|file):\/\/[-A-Za-z0-9+&@#/%?=~_|!:,.;]+[-A-Za-z0-9+&@#/%=~_|]/;
    if (!(regFormat.test(value))) {
      callback(new Error(option.msg));
    } else {
      callback();
    }
  },

  /* 验证手机号 */
  isValidatePhone(rule, value, callback, option) {
    let regFormat1 = /^[1][0-9]{10}$/; // 正确手机号
    let regFormat2 = /^([5|6|8|9])\d{7}$/; // 香港手机号规则
    if (!regFormat1.test(value) && !(regFormat2.test(value))) {
      callback(new Error(option.msg));
    } else {
      callback();
    }
  },

  /* 验证只能有中文 */
  isValidateChinese(rule, value, callback, option) {
    let regFormat = /[^\u4e00-\u9fa5]/;
    if ((regFormat.test(value))) {
      callback(new Error(option.msg));
    } else {
      callback();
    }
  },

  /* 验证数字，金额的正确性 */
  isValidateNumber(rule, value, callback, option) {
    let regFormat = new RegExp(/^(([0-9]+.\d+)|([1-9][0-9]*))$/);
    if ((!regFormat.test(value)) || value == 0) {
      callback(new Error(option.msg));
    } else {
      callback();
    }
  },

  /* 验证银行卡 */
  isValidateBankCard(rule, value, callback, option) {
    let regFormat = /^\d{16,19}$/;
    if (!regFormat.test(value)) {
      callback(new Error(option.msg));
    } else {
      callback();
    }
  },

  /* 验证18位身份证 */
  isValidateIdCard(rule, value, callback, option) {
    // 15位和18位身份证号码的正则表达式
    var regIdCard = /^(^[1-9]\d{7}((0\d)|(1[0-2]))(([0|1|2]\d)|3[0-1])\d{3}$)|(^[1-9]\d{5}[1-9]\d{3}((0\d)|(1[0-2]))(([0|1|2]\d)|3[0-1])((\d{4})|\d{3}[Xx])$)$/;
    // 如果通过该验证，说明身份证格式正确，但准确性还需计算
    if (regIdCard.test(value)) {
      if (value.length == 18) {
        var idCardWi = new Array(7, 9, 10, 5, 8, 4, 2, 1, 6, 3, 7, 9, 10, 5, 8, 4, 2); // 将前17位加权因子保存在数组里
        var idCardY = new Array(1, 0, 10, 9, 8, 7, 6, 5, 4, 3, 2); // 这是除以11后，可能产生的11位余数、验证码，也保存成数组
        var idCardWiSum = 0; // 用来保存前17位各自乖以加权因子后的总和
        for (var i = 0; i < 17; i++) {
          idCardWiSum += value.substring(i, i + 1) * idCardWi[i];
        }
        var idCardMod = idCardWiSum % 11;// 计算出校验码所在数组的位置
        var idCardLast = value.substring(17);// 得到最后一位身份证号码
        // 如果等于2，则说明校验码是10，身份证号码最后一位应该是X
        if (idCardMod == 2) {
          if (idCardLast == 'X' || idCardLast == 'x') {
            callback();
          } else {
            callback(new Error(option.msg));
          }
        } else {
          // 用计算出的验证码与最后一位身份证号码匹配，如果一致，说明通过，否则是无效的身份证号码
          if (idCardLast == idCardY[idCardMod]) {
            let timeStr = value.substring(6, 10) + '-' + value.substring(10, 12) + '-' + value.substring(12, 14);
            if (new Date(timeStr) <= new Date()) { // 判断身份证时间是否比当前时间小
              callback();
            } else {
              callback(new Error(option.msg));
            }
          } else {
            callback(new Error(option.msg));
          }
        }
      }
    } else {
      callback(new Error(option.msg));
    }

    /*
        let regFormat = /^[1-9]\d{5}(18|19|([23]\d))\d{2}((0[1-9])|(10|11|12))(([0-2][1-9])|10|20|30|31)\d{3}[0-9Xx]$/;
        if (!regFormat.test(value)) {
            callback(new Error(option.msg));
        } else {
            callback();
        }
    */
  },

  /* 验证香港身份证 */
  /*
   *            香港身份证号码由三部分组成：一个英文字母；6个数字；括号及0-9中的任一个数字，或者字母A。
   *            括号中的数字或字母A，是校验码，用于检验括号前面的号码的逻辑正确性
   *            逻辑关系：
   *            1、先把首位字母改为数字，即A为1，B为2，C为3...Z为26，再乘以8；
   *            2、然后把字母后面的6个数字依次乘以7、6、5、4、3、2；
   *            3、再将以上所有乘积相加的和，除以11，得到余数；
   *            4、如果整除，则括号中的校验码为0，如果余数为1，则校验码为A，如果余数为2～10，则用11减去这个余数的差作校验码
   */
  isValidateIdCardHK(rule, value, callback, option) {
    if (value.length != 10) {
      callback(new Error(option.msg));
    }
    if (!new RegExp(/^[A-Z]{1}[0-9]{6}\([0-9A]\)$/).test(value)) {
      callback(new Error(option.msg));
    }

    let firstCode = value.charCodeAt(0);
    let numStr = value.substring(1, 7);
    let sum = 0;
    sum += (firstCode - 64) * 8;
    for (let i = numStr.length; i > 0; i--) {
      sum += (i + 1) * numStr[6 - i];
    }
    let result = sum % 11;
    if (result == 0 && value.charAt(8) == '0') {
      callback();
      return;
    } else if (result == 1 && value.charAt(8) == 'A') {
      callback();
      return;
    } else if (Number(value.charAt(8)) + result == 11) {
      callback();
      return;
    } else {
      callback(new Error(option.msg));
    }
  },

  /* 验证证件的正确性 1-大陆证件 2-香港居民身份证 3-护照*/
  isValidatePaper(rule, value, callback, option) {
    var regFormat = '';
    /* 判断证件类型 */
    if (option.paperType == '1') {
      regFormat = /^\d{18}$/;
    } else if (option.paperType == '2' || option.paperType == '3') {
      regFormat = /^[a-zA-Z0-9]+$/;
    }
    if (!regFormat.test(value)) {
      callback(new Error(option.msg));
    } else {
      callback();
    }
  },

  /* 验证正则 */
  isValidateReg(rule, value, callback, option) {
    let regFormat = new RegExp(option.pattern);
    if (regFormat.test(value)) {
      callback();
    } else {
      callback(new Error(option.msg));
    }
  },

  /* 验证可空或者别的规则 */
  isValidateNull(rule, value, callback, option) {
    if(!value) {
      callback();
    } else {
      if (options.otherRule) {
        let otherRule = option.otherRule;
        strategies['isValidate' + otherRule](rule, value, callback, option);
      } else {
        callback();
      }
    }
  }
};
