<template>
  <div :class="classObj" class="app-wrapper">
    <div v-if="device==='mobile'&&sidebarCollapse" class="drawer-bg" @click="handleClickOutside"/>
    <sidebar class="sidebar-container"/>
    <div class="main-container">
      <div :class="{'fixed-header':fixedHeader}">
        <navbar/>
      </div>
      <AppMain/>
    </div>
  </div>
</template>

<script>
  import {AppMain, Navbar, Sidebar} from './components'
  import ResizeMixin from './mixin/ResizeHandler'

  export default {
    name: 'Layout',
    components: {
      Navbar,
      Sidebar,
      AppMain,
    },
    mixins: [ResizeMixin],
    computed: {
      sidebarCollapse() {
        return this.$store.state.app.sidebarCollapse
      },
      device() {
        return this.$store.state.app.device
      },
      fixedHeader() {
        return this.$store.state.settings.fixedHeader
      },
      classObj() {
        return {
          hideSidebar: !this.sidebarCollapse,
          openSidebar: this.sidebarCollapse,
          withoutAnimation: false,
          mobile: this.device === 'mobile'
        }
      },

    },
    methods: {
      handleClickOutside() {
        this.$store.dispatch('toggleSideBar', false)
      }
    },
    mounted() {
      this.$store.dispatch('getNoReadNotify')
    }
  }
</script>

<style lang="scss" scoped>
  @import "~@/styles/variables.scss";
  .app-wrapper {
    position: relative;
    height: 100%;
    width: 100%;
    display: flex;
    .sidebar-container {
      display: flex;
      position: fixed;
      top: 0;
      bottom: 0;
      left: 0;
      width: auto;
      z-index: 1001;
    }
    .main-container {
      flex: 1 0 980px;
      width: 0;
    }
  }

  .drawer-bg {
    background: #000;
    opacity: 0.3;
    width: 100%;
    top: 0;
    height: 100%;
    position: absolute;
    z-index: 999;
  }

  .fixed-header {
    position: fixed;
    top: 0;
    right: 0;
    z-index: 9;
    transition: width 0.28s;
  }
</style>
