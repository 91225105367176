import Vue from 'vue'

import 'normalize.css/normalize.css' // A modern alternative to CSS resets
import ElementUI from 'element-ui'
import 'element-ui/lib/theme-chalk/reset.css';
import 'element-ui/lib/theme-chalk/index.css'

import '@/styles/index.scss' // global css
import App from './App'
import store from './store'
import router from './router'
import mixin from '@/mixin'
import VToggle from '@/directive/toggle'
import VClipboard from '@/directive/clipboard'

// 过滤器
import filters from '@/filter/filter.js';
Object.keys(filters).forEach(key => {
  Vue.filter(key, filters[key])
})
import '@/icons' // icon
import '@/permission' // permission control
import '@/utils/validate.js'
import format from '@/utils/format.js'
import VPermission from '@/directive/permission'
import VPreview from '@/directive/preview'

import datePicker from '@/components/DatePicker/datePicker.vue';
import warningBlock from '@/components/WarningBlock';
import addressSelect from '@/components/AddressSelect';

Vue.component('date-picker', datePicker);
Vue.component('warning-block', warningBlock);
Vue.component('address-select', addressSelect);
//install v-permission
VPermission.install(Vue)
Vue.use(VPreview)

// set ElementUI lang to EN
Vue.use(ElementUI)

Vue.use(VToggle)
Vue.use(VClipboard)

Vue.mixin(mixin)

Vue.config.productionTip = false

export default new Vue({
  el: '#app',
  router,
  store,
  render: h => h(App)
})

Vue.prototype.$format = format;
Vue.prototype.$dict = (dataName) => {
  let dictionary = store.getters.userData && store.getters.userData.dictionary;
  if (dictionary && dictionary[dataName]) {
    return dictionary[dataName]
  } else {
    return []
  }
};


Vue.prototype.$dictFlag = (dataName, dataFlag) => {
  let dictionary = store.getters.userData && store.getters.userData.dictionary;
  if (dictionary && dictionary[dataName]) {
    for (let item of dictionary[dataName]) {
      if (item.flag === String(dataFlag)) {
        return item;
      }
    }
  }
  return {}
};

Vue.prototype.$dictCode = (dataName, dataCode) => {
  let dictionary = store.getters.userData && store.getters.userData.dictionary;
  if (dictionary && dictionary[dataName]) {
    for (let item of dictionary[dataName]) {
      if (item.code === String(dataCode)) {
        return item;
      }
    }
  }
  return {}
}

Vue.prototype.$dictDesc = (dataName, dataDesc) => {
  let dictionary = store.getters.userData && store.getters.userData.dictionary;
  if (dictionary && dictionary[dataName]) {
    for (let item of dictionary[dataName]) {
      if (item.desc === String(dataDesc)) {
        return item;
      }
    }
  }
  return {}
}

