import Vue from 'vue'
import Router from 'vue-router'
/* Layout */
import Layout from '@/layout'

Vue.use(Router)

export const constantRoutes = [
  {
    path: '/login',
    component: () => import(/* webpackChunkName: "constant-group" */ '@/views/common/login'),
    hidden: true
  },
  {
    path: '/retrievePwd',
    component: () => import(/* webpackChunkName: "constant-group" */ '@/views/common/retrievePwd'),
    hidden: true
  },
  {
    path: '/changePwd',
    component: () => import(/* webpackChunkName: "constant-group" */ '@/views/common/changePwd'),
    hidden: true
  },
  {
    path: '/selectMch',
    component: () => import(/* webpackChunkName: "constant-group" */ '@/views/common/selectMch'),
    hidden: true
  },
  {
    path: '/accountManagement',
    component: () => import(/* webpackChunkName: "constant-group" */ '@/views/common/accountManagement'),
    hidden: true
  },
  {
    path: '/setting',
    component: () => import(/* webpackChunkName: "constant-group" */ '@/views/common/setting'),
    hidden: true
  },
  {
    path: '/404',
    component: () => import(/* webpackChunkName: "constant-group" */ '@/views/common/404'),
    hidden: true
  },
  {
    path: '/redirect',
    component: Layout,
    hidden: true,
    children: [
      {
        path: '/redirect/:path*',
        component: () => import(/* webpackChunkName: "constant-group" */ '@/views/common/redirect')
      },
      {
        path: '/safe/leaderChange',
        component: () => import(/* webpackChunkName: "constant-group" */ '@/views/system/leaderChange')
      },
      {
        path: '/safe/emailChange',
        component: () => import(/* webpackChunkName: "constant-group" */ '@/views/system/emailChange')
      },
      {
        path: '/safe/tradePwdChange',
        component: () => import(/* webpackChunkName: "constant-group" */ '@/views/system/tradePwdChange')
      },
      {
        path: '/merchant/staffForm',
        component: () => import(/* webpackChunkName: "constant-group" */ '@/views/merchant/staffForm')
      },
      {
        path: '/merchant/auth',
        component: () => import(/* webpackChunkName: "constant-group" */ '@/views/merchant/auth')
      },
      {
        path: '/trade/order/deliver',
        component: () => import(/* webpackChunkName: "constant-group" */ '@/views/trade/order/deliver')
      },
      {
        path: '/finance/issueInvoice/issueDetail',
        component: () => import(/* webpackChunkName: "finance-group" */ "@/views/finance/issueInvoice/issueDetail")
      },
      {
        path: '/merchant/manage/merchantInfo',
        component: () => import(/* webpackChunkName: "merchant-group" */ "@/views/merchant/manage/merchantInfo")
      },
      {
        path: '/merchant/agreement/finishAgreement',
        component: () => import(/* webpackChunkName: "merchant-group" */ "@/views/agreement/finishAgreement")
      },
      {
        path: '/audit/detailProcess',
        component: () => import(/* webpackChunkName: "process-group" */ '@/views/waitingHandle/flowDetail'),
      },
      {
        path: '/audit/workform/detail',
        component: () => import(/* webpackChunkName: "process-group" */ '@/views/waitingHandle/workform/detail'),
      },
      {
        path: '/audit/workform/create',
        component: () => import(/* webpackChunkName: "process-group" */ '@/views/waitingHandle/workform/create'),
      },
      {
        path: '/audit/workform/generate',
        component: () => import(/* webpackChunkName: "process-group" */ '@/views/waitingHandle/workform/generate'),
      },
      {
        path: '/notify/list',
        component: () => import(/* webpackChunkName: "notify-group" */ '@/views/notify/list'),
      }
    ]
  },
]

const createRouter = () => new Router({
  // mode: 'history', // require service support
  scrollBehavior: () => ({
    y: 0
  }),
  routes: constantRoutes
})

const router = createRouter()

// Detail see: https://github.com/vuejs/vue-router/issues/1234#issuecomment-357941465
export function resetRouter() {
  const newRouter = createRouter()
  router.matcher = newRouter.matcher // reset router
}

export default router

let routesInfo = {
  /* 设置 */
  "system:safe": () => import(/* webpackChunkName: "system-group" */ "@/views/system/safeSetting"),
  "pms:staff:view": () => import(/* webpackChunkName: "merchant-group" */ "@/views/merchant/staff"),
  "pms:role:view": () => import(/* webpackChunkName: "merchant-group" */ "@/views/merchant/role"),
  "pms:info:view": () => import(/* webpackChunkName: "merchant-group" */ "@/views/merchant/info"),
  "pms:merchant:auth": () => import(/* webpackChunkName: "merchant-group" */ "@/views/merchant/auth"),

  /* 交易 */
  "trade:order:upload": () => import(/* webpackChunkName: "trade-group" */ "@/views/trade/order/upload"),
  "trade:order:roster": () => import(/* webpackChunkName: "trade-group" */ "@/views/trade/order/roster"),
  "trade:order:detail": () => import(/* webpackChunkName: "trade-group" */ "@/views/trade/order/detail"),
  "trade:order:hang": () => import(/* webpackChunkName: "trade-group" */ "@/views/trade/order/hang"),
  "trade:order:receipt": () => import(/* webpackChunkName: "trade-group" */ "@/views/trade/order/receipt"),

  "trade:outer:roster": () => import(/* webpackChunkName: "trade-group" */ "@/views/trade/order/roster"),
  "trade:outer:detail": () => import(/* webpackChunkName: "trade-group" */ "@/views/trade/order/detail"),

  /* 财务 */
  "invoice:view": () => import(/* webpackChunkName: "finance-group" */ "@/views/finance/issueInvoice/issueRecord"),
  'invoice:tax': () => import(/* webpackChunkName: "finance-group" */ "@/views/finance/tax/taxProfile"),

  /* 电子签约 */
  'sign:signRecord:view': () => import(/* webpackChunkName: "trade-group" */ '@/views/sign/signInfo'),

  /* 主体认证 */
  'merchant:mainstay:mainAuth': () => import(/* webpackChunkName: "merchant-group" */ '@/views/merchant/auth'),

  /* 商户 */
  'merchant:manage:view': () => import(/* webpackChunkName: "merchant-group" */ '@/views/merchant/manage/list'),

  /* 协议 */
  'merchant:protocal:view': () => import(/* webpackChunkName: "merchant-group" */ '@/views/agreement/list'),

  /* 账户 */
  'account:withdraw:view': () => import(/* webpackChunkName: "account-group" */ '@/views/account/withdraw/withdraw'),
  'account:withdrawRecord:list': () => import(/* webpackChunkName: "account-group" */ '@/views/account/withdraw/withdrawRecord'),
  'account:balance:view': () => import(/* webpackChunkName: "account-group"*/ '@/views/account/balance/checkBalance'),

  /* 待办 */
  'audit:process': () => import(/* webpackChunkName: "process-group" */ '@/views/waitingHandle/flow'),
  'audit:workform:view': () => import(/* webpackChunkName: "process-group" */ '@/views/waitingHandle/workform/list'),

  /* 数据 */
  'data:manage:person': () => import(/* webpackChunkName: "data-group" */ '@/views/data/person/list'),
  'data:manage:company': () => import(/* webpackChunkName: "data-group" */ '@/views/data/company/list'),

    /**账单管理 */
    'bill:view': () => import(/* webpackChunkName: "bill-group" */ '@/views/bill/index'),
};

export const addRoutes = function (functions) {
  let menuRoute = {
    path: '/',
    component: Layout,
    children: []
  };
  functions.filter(f => f.type === 1).forEach(f => {
    menuRoute.children.push({
      component: routesInfo[f.permissionFlag],
      path: f.url,
      meta: {
        id: f.id,
        title: f.name,
        permissionFlag: f.permissionFlag
      }
    });
  });
  router.addRoutes([menuRoute]) // 添加路由
}
