import Cookies from 'js-cookie'

const PublicKey = 'p_key'

export function getKey() {
  return Cookies.get(PublicKey)
}

export function setKey(key) {
  return Cookies.set(PublicKey, key)
}

export function removeKey() {
  return Cookies.remove(PublicKey)
}
