import request from '@/utils/request.js';

// 消息列表
export function getNotifyList(data) {
  return request({
    url: '/notification/listNotificationRecordFullInfo',
    method: 'post',
    data,
  })
}

// 更新消息状态
export function updateNotifyStatus(data) {
  return request({
    url: '/notification/updateNotificationDetailReadStatus',
    method: 'post',
    data,
  })
}

// 未读消息
export function noReadNotify() {
  return request({
    url: '/notification/getNoReadAccount',
    method: 'get',
  })
}

// 获取消息
export function getNotify(params) {
  return request({
    url: '/notification/notificationInfoById',
    method: 'get',
    params
  });
}

// 首页通知
export function getIndexNotify() {
  return request({
    url: '/notification/notifyList',
    method: 'get',
  })
}
