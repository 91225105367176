import router from "@/router"
import store from "@/store"
import NProgress from "nprogress" // progress bar
import "nprogress/nprogress.css" // progress bar style
import { getToken, removeToken } from "@/utils/loginToken" // get token from cookie
import { removeKey } from "@/utils/publicKey"
import { getUserInfo, getDictionaryVO } from "@/api/common"
import { addRoutes } from "@/router"
import Vue from 'vue'

NProgress.configure({showSpinner: false}) // NProgress Configuration

const whileList = ["/login", "/retrievePwd"];  // 不需要登录可以访问的页面

router.beforeEach(async (to, from, next) => {
  NProgress.start();
  // 登录信息与用户初始化信息（包含菜单，权限，路由，数据字典等）
  const token = getToken()

  if (whileList.indexOf(to.path) !== - 1) {  // 白名单地址
    next();
  } else if (token) {
    let userData = store.getters.userData;
    if (userData) {         // 已经获取到数据，直接跳转
      next();
    } else {
      userData = await getUserData();        // 获取用户数据
      if (!userData) {
        removeToken();
        removeKey();
        next(`login?redirect=${to.path}`)
      } else {
        next({ ...to, replace: true })
      }
    }
    store.state.user.menus && initRightMenu(store.state.user.menus, to.path); // 初始化侧边栏子菜单
  } else if (to.path === "/login") {
    next();
  } else {
    next(`/login?redirect=${to.path}`);
  }

  NProgress.done();
})

router.afterEach(() => { })

export function addRoutersAndBuildMenu(functions) {
  addRoutes(functions);
  store.dispatch("setMenus", buildMenu(functions));
  store.dispatch("setPermissionFlags", new Set(functions.map(f => f.permissionFlag)));
}

async function getUserData() {
  const p1 = getUserInfo();
  const p2 = getDictionaryVO();

  const [{data: userData}, { data: dictionaryList }] = await Promise.all([p1, p2]);
  if (userData.functions) {
    addRoutersAndBuildMenu(userData.functions);
  }
  let dictionary = dictionaryList.reduce((map, item) => {
    map[item.dataName] = item.dataInfo;
    return map
  }, {})
  let combinedUserData = Object.assign({}, userData, { dictionary });
  return store.dispatch("setUserData", combinedUserData);
}

function buildMenu(functions) {
  let pidGroup = {};
  functions.filter(f => f.type === 1).forEach(f => {
    if (!pidGroup[f.parentId]) {
      pidGroup[f.parentId] = [f]
    } else {
      pidGroup[f.parentId].push(f)
    }
  });
  let resultArr = [];
  buildMenuTree(0, pidGroup, resultArr);
  sortByNumber(resultArr);
  return resultArr;
}

function sortByNumber(array) {
  if (array.length == 0) return;
  array.sort((a, b) => {
    if (a.meta.number < b.meta.number) {
      return -1
    } else {
      return 1
    }
  })
  array.forEach(item => {
    if (item.children) {
      sortByNumber(item.children);
    }
  })
}

function buildMenuTree(pid, pidGroup, resultArr) {
  let group = pidGroup[pid];
  if (!group) {
    return;
  }
  group.forEach((p) => {
    let menu = {}
    menu.meta = {
      title: p.name,
      number: p.number,
    }
    menu.path = p.url;
    if (pidGroup[p.id]) {
      menu.children = [];
      buildMenuTree(p.id, pidGroup, menu.children)
    }
    resultArr.push(menu)
  })
}

async function initRightMenu(menus, path) {
  if (store.state.user.rightMenu !== null || !menus.length) return;
  const nextTick = Vue.nextTick;
  await nextTick();
  const currPath = path.split('/')[1];
  for (let i = 0; i < menus.length; i++) {
    const tempPath = menus[i].path.split('/')[1];
    if (tempPath === currPath) {
      store.dispatch('setRightMenu', menus[i]['children'])
      break;
    }
  }
}
