const state = {
  userData: undefined,
  leftMenu: null,
  rightMenu: null,
  menus: null,
  permissionFlags: null,
  key: null,
  permissionPathMap: null,
}

const mutations = {
  SET_USER_NAME: (state, name) => {
    state.userData.operator.name = name;
  },
  SET_USER_DATA: (state, userData) => {
    state.userData = userData
  },
  SET_MENUS: (state, data) => {
    state.menus = data
  },
  SET_LEFTMENU: (state, data) => {
    state.leftMenu = data
  },
  SET_RIGHTMENU: (state, data) => {
    state.rightMenu = data
  },
  SET_PERMISSIONFLAGS: (state, data) => {
    state.permissionFlags = data
  },
  SET_USER_AVATAR: (state, data) => {
    state.userData.operator.headPortraitFileUrl = data;
  },
  SET_KEY: (state, data) => {
    state.key = data;
  },
  SET_PERMISSION_PATH_MAP: (state, data) => {
    state.permissionPathMap = data
  }
}

const actions = {
  setUserData({commit, dispatch}, userData) {
    return new Promise(resolve => {
      commit('SET_USER_DATA', userData);
      let map = {}
      if (userData && userData.functions) {
        userData.functions.forEach(f => {
          if (f.url) {
            map[f.permissionFlag] = f.url
          }
        })
      }
      dispatch('setPermissionPathMap', map)
      resolve(userData)
    })
  },
  setMenus({ commit }, menus) {
    return new Promise(resolve => {
      commit('SET_MENUS', menus);
      resolve(menus)
    })
  },
  setPermissionFlags({ commit }, permissionFlags) {
    return new Promise(resolve => {
      commit("SET_PERMISSIONFLAGS", permissionFlags);
      resolve(permissionFlags);
    })
  },
  setLeftMenu({commit}, data) {
    return new Promise(resolve => {
      commit('SET_LEFTMENU', data)
      resolve(data)
    })
  },
  setRightMenu({commit}, data) {
    return new Promise(resolve => {
      commit('SET_RIGHTMENU', data)
      resolve(data)
    })
  },
  setKey({ commit }, data) {
    return new Promise(resolve => {
      commit('SET_KEY', data);
      resolve()
    })
  },
  setPermissionPathMap({ commit }, data) {
    return new Promise(resolve => {
      commit('SET_PERMISSION_PATH_MAP', data);
      resolve()
    })
  },
}

export default {
  state,
  mutations,
  actions
}
