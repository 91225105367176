import previewVM, { preview } from './preview';

const install = (Vue) => {
  Vue.directive('preview', previewVM)
  Vue.prototype.$preview = preview
}

if (window.Vue) {
  window.Vue.use(install);
}

previewVM.install = install;

export default previewVM;
