<template>
  <el-cascader
    class="address-select"
    ref="cascader"
    :props="props"
    v-model="addresslist"
    @change="handleChange">
  </el-cascader>
</template>

<script>
import { getProvinceList, getCityList, getTownList } from '@/api/common'

export default {
  model: {
    prop: 'address',
    event: 'change'
  },
  props: {
    address: {
      type: Array,
      default: () => []
    },
    defaultAddress: String,
    province: String,
    city: String,
    town: String,
  },
  data() {
    return {
      addresslist: [],
      props: {
        lazy: true,
        lazyLoad(node, resolve) {
          const { level } = node;
          let api = level == 0 ?
            getProvinceList : (level == 1 ? getCityList : level == 2 ? getTownList : null)
          let value = level == 0 ?
            'provinceNo' : (level == 1 ? 'cityNo' : 'townNo')
          let label = level == 0 ?
            'provinceName' : (level == 1 ? 'cityName' : 'townName')
          let params = level == 1 ?
            'provinceNo' : (level == 2 ? 'cityNo' : '')
          let requestParams = {};
          if (node.data && node.data.value) {
            requestParams[params] = node.data.value[0];
          }
          if (api) {
            api(requestParams).then(({data}) => {
              let nodes = data.map(item => ({
                value: [item[value], item[label]],
                label: item[label],
                leaf: level == 2 || item[label] == '台湾省' || item[label] == '香港特别行政区' || item[label] == "澳门特别行政区"
              }));
              resolve(nodes);
            })
          }
        }
      },
    }
  },

  watch: {
    defaultAddress(val) {
      this.changeDefault(val);
    }
  },

  methods: {
    handleChange(val) {
      this.$emit('change', val);
      const events = ['update:province', 'update:city', 'update:town'];
      for (let i = 0; i < events.length; i++) {
        let value = '';
        if (val[i]) {
          value = val[i][1];
        }
        this.$emit(events[i], value)
      }
    },
    changeDefault(val) {
      this.$refs.cascader.inputValue = val;
    }
  }
}
</script>

<style lang="scss" scoped>
  .address-select {
    width: 450px;
  }
</style>
