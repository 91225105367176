import request from '@/utils/request.js';

// 获取图形验证码
export function getCaptcha() {
  return request({
    url: "/user/captcha",
    method: "post",
  })
}

// 发送短信验证码
export function sendSmsCode(data) {
  return request({
    url: "/user/sendSmsCode",
    method: "post",
    params: data
  })
}

// 登录
export function login(data) {
  return request({
    url: "/user/login",
    method: "post",
    data
  })
}

// 设置登录密码（使用手机验证码）
export function resetPwd(data) {
  return request({
    url: "/user/resetPwd",
    method: "post",
    data
  })
}

// 查询可选商户列表
export function getMchList() {
  return request({
    url: "/user/mchList",
    method: "get"
  })
}

// 选择商户
export function selectMch(data) {
  return request({
    url: "/user/selectMch",
    method: "get",
    params: data
  })
}

// 获取用户信息
export function getUserInfo() {
  return request({
    url: "/user/info",
    method: "get"
  })
}

// 修改登录密码
export function updateLoginPwd(data) {
  return request({
    url: "/user/updatePwd",
    method: "post",
    data
  })
}

// 修改用户名
export function updateUserInfo(data) {
  return request({
    url: "/user/updateInfo",
    method: "post",
    data
  })
}

// 获取用户状态（判断是否设置密码）
export function getAccountStatus(data) {
  return request({
    url: "/user/getUserStatus",
    method: "get",
    params: data
  })
}

// 获取RSA密码
export function getUserPublickey(params) {
  return request({
    url: '/user/getUserPublickey',
    method: 'get',
    params
  })
}


// 获取角色列表
export function getRoleList(data) {
  return request({
    url: "/role/listAll",
    method: "get"
  })
}

// 获取省市地区
export function getProvinceList(data) {
  return request({
    url: '/province/listAllProvince',
    method: 'get',
  })
}

export function getCityList(data) {
  return request({
    url: '/province/listCityByProvinceNo',
    method: 'get',
    params: data
  })
}

export function getTownList(data) {
  return request({
    url: '/province/listTownByCityNo',
    method: 'get',
    params: data
  })
}

// 获取银行
export function getBankList(data) {
  return request({
    url: '/bankInfo/getBankInfo',
    method: 'get',
    params: data
  })
}


// 获取数据字典
export function getDictionaryVO() {
  return request({
    url: '/portalDataDictionary/listAllDataDictionaryVO',
    method: 'get'
  })
}

export function getExportRecord(data) {
  return request({
    url: '/file/listExportRecord',
    method: 'get',
    params: data
  })
}

export function uploadFile(data) {
  return request({
    url: '/file/upload',
    method: 'post',
    data
  })
}

