function toCurrency(num) {
  let numberStr = num.toString();
  let arr = numberStr.split(".");
  let symbol = "";
  let currency = arr[0];
  if (currency.indexOf("-") != -1) {
    symbol = "-";
  }
  currency = currency.replace("-", "");
  currency = currency.split("").reverse();
  for (let i = 0; i < currency.length; i++) {
    if ((i + 1) % 4 === 0) {
      currency.splice(i, 0, ",");
    }
  }
  currency.reverse();
  let handleResult = "";
  for (let j = 0; j < currency.length; j++) {
    handleResult += currency[j];
  }
  if (arr[1]) {
    handleResult = handleResult + "." + arr[1];
  } else {
    handleResult = handleResult + ".00";
  }
  return symbol + handleResult;
}

export default {
  toCurrency: function (num) {
      return toCurrency(num)
  },
} 