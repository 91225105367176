import { getToken } from '@/utils/loginToken';
import { mapGetters } from 'vuex'

export default {
  computed: {
    ...mapGetters(['userData']),
    fileUrl() {
      switch (process.env.VUE_APP_FLAG) {
        case 'dev':
          return this.$dict('FileSystemUrl')[0].desc;
        case 'test':
          return 'https://static.hjzx.tech/';
        case 'production':
          return 'https://static.hjzxh.com/';
        default:
          return '';
      }
    },
    baseUrl() {
      return process.env.VUE_APP_BASE_API
    },
    uploadHeader() {
      return {
        'X-Token': getToken()
      }
    },
  },
  methods: {
    hadPermission(permission) {
      return this.$store.state.user.permissionFlags.has(permission);
    },
    renderTime(time) {
      if (!time) return '';
      const arr = time.split(' ');
      return `<span>${ arr[0] }</span><br/><span>${ arr[1] }</span>`
    },
    permissionToPath(permission) {
      return this.$store.state.user.permissionPathMap[permission] || ''
    },
    handleNumberInput(obj, prop, value) {
      let val = value;
      val = val.replace(/[^(\d)]/g, '');
      obj[prop] = val;
    },
    handleRateInput(obj, rateStr, value, min, max) {
      let val = String(value)
      val = val.replace(/[^(\d.)]/g, ''); // 去除非数字和小数点
      val = val.replace(/(\.)(\d*)\./, '$1$2'); // 去除多余小数点
      let valid = true;
      if (min !== undefined) {
        valid = (val >= min)
      }
      if (max !== undefined) {
        valid = (val <= max)
      }
      if (valid) {
        obj[rateStr] = val;
      }
    },
    isPdf(url) {
      return /\.pdf$/i.test(url);
    },
    validateUploadFile(options) {
      let self = this;
      return function (file) {
        let isTrueType = false;
        let isLimitSize = true;
        if (options.img) {
          isTrueType = ['image/jpeg', 'image/png', 'image/bmp', 'image/gif'].includes(file.type);
        }
        if (options.pdf) {
          isTrueType = isTrueType || ['application/pdf'].includes(file.type);
        }
        if (options.word) {
          isTrueType = isTrueType || /(\.doc|\.docx)$/i.test(file.name)
        }
        if (options.addition && Array.isArray(options.addition)) {
          let reg = new RegExp('(' + options.addition.join('|') + ')$', 'i');
          isTrueType = isTrueType || reg.test(file.name)
        }
        if (options.size) {
          isLimitSize = file.size / 1024 / 1024 < options.size
        }

        if (!isLimitSize) {
          self.$message.error(`上传文件大小不能超过 ${ options.size } MB!`);
        }

        if (!isTrueType) {
          let type = [];
          if (options.pdf) {
            type.push('pdf')
          }
          if (options.word) {
            type.push('doc、docx')
          }
          if (options.img) {
            type.push('bmp、png、jpeg、jpg或gif')
          }
          if (options.addition) {
            type.push(options.addition.join('、'))
          }
          type = type.join('、');
          self.$message.error(`上传文件只能是 ${ type } 格式!`);
        }
        return isLimitSize && isTrueType
      }
    },
    isImg(url) {
      const typeList = ['.png', '.jpg', '.jpeg', '.bmp', '.gif'];
      const reg = new RegExp(`(${typeList.join('|')})$`, 'i');
      return reg.test(url);
    },
    downloadFile(url, name = '下载文件') {
      // const a = document.createElement('a');
      // a.download = name;
      // a.href= url;
      // a.target = '_blank';
      // document.body.appendChild(a);
      // a.click();
      // document.body.removeChild(a);

      // 利用文件流下载，绕过预览
      let ext = url.split('.').pop();
      // 去除文件扩展名
      let reg = new RegExp(`\\.${ext}$`, 'i')
      name = name.replace(reg, '')
      const xhr = new XMLHttpRequest();
      xhr.open('get', url);
      xhr.responseType = 'blob';
      xhr.send();
      xhr.onload = function () {
        if (this.status === 200 || this.status === 304) {
          // 如果是IE10及以上，不支持download属性，采用msSaveOrOpenBlob方法，但是IE10以下也不支持msSaveOrOpenBlob
          if ('msSaveOrOpenBlob' in navigator) {
            navigator.msSaveOrOpenBlob(this.response, name);
            return;
          }
          const url = URL.createObjectURL(this.response);
          let eleLink = document.createElement("a");
          eleLink.href = url;
          eleLink.download = `${ name }.${ ext }`;
          eleLink.style.display = "none";
          document.body.appendChild(eleLink);
          eleLink.click();
          document.body.removeChild(eleLink);
          URL.revokeObjectURL(url);
        }
      };
    },
    downloadByLink(url, fileName = '') {
      // 导出文件下载方法
      const mime = url.match(/\.(\w+)/i)[0]
      let a = document.createElement('a');
      a.download = fileName + mime;
      a.href = url;
      document.body.appendChild(a);
      a.click();
      document.body.removeChild(a);
    },
    sleep(time = 1000) {
      return new Promise(resolve => {
        setTimeout(resolve, time)
      })
    }
  }
}
