import toggle from './toggle'

const install = (Vue) => {
  Vue.directive('toggle', toggle)
}

if (window.Vue) {
  window.Vue.use(install);
}

toggle.install = install;

export default toggle;