<template>
  <section class="app-main">
    <router-view :key="key"/>
  </section>
</template>

<script>
import { getIndexNotify, getNotify, updateNotifyStatus } from "@/api/notify";
import { notify } from "@/utils/notify";

  export default {
    name: 'AppMain',
    computed: {
      key() {
        return this.$route.path
      },
    },
    mounted() {
      this.getNotifyList()
    },
    methods: {
      async getNotifyList() {
        const { data: { array: data } } = await getIndexNotify()
        let array = data.filter(i => i.pop && i.readStatus === 0)
        for (let i = 0; i < array.length; i++) {
          getNotify({ id: array[i].id }).then(res => {
            const { data } = res
            notify({
              title: data.notificationTitle,
              message: data.notificationContent,
              onClose() {
                updateNotifyStatus({
                  ids: data.id,
                  status: 1,
                })
              }
            })
          })
        }
      },
    }
  }
</script>

<style scoped>
  .app-main {
    /*50 = navbar  */
    min-height: calc(100vh - 50px);
    width: 100%;
    position: relative;
    background: #F0F2F5;
    padding: 16px;
  }

  .fixed-header + .app-main {
    padding-top: 50px;
  }
</style>

<style lang="scss">
  // fix css style bug in open el-dialog
  .el-popup-parent--hidden {
    .fixed-header {
      padding-right: 15px;
    }
  }
</style>
