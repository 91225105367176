<template>
  <el-dialog
    align="center"
    :visible="visible"
    :beforeClose="close"
    :append-to-body="true">
    <template v-if="src.length !== 0">
      <div v-if="isOneImg">
        <img :src="src" />
      </div>
      <div v-else>
        <el-carousel
          arrow="always"
          :autoplay="false">
          <el-carousel-item
            v-for="(i, key) in src"
            :key="key"
          >
            <img :src="i" style='height: 100%; object-fit: scale-down'/>
          </el-carousel-item>
        </el-carousel>
      </div>
    </template>
    <template v-else>
      <div class="preview-loading">
        <i class="el-icon-loading"></i>
      </div>
    </template>
  </el-dialog>
</template>

<script>
  export default {
    data() {
      return {
        visible: false,
        src: null,
        initialIndex: 0,
      }
    },
    computed: {
      isOneImg() {
        return typeof this.src === 'string'
      }
    },
    methods: {
      close() {
        this.visible = false;
        this.$nextTick(() => {this.src = null})
      },
      open() {
        this.visible = true;
      }
    },
  }
</script>

<style scoped lang="scss">
  .preview-loading {
    font-size: 60px;
  }
</style>
