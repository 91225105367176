import dayjs from 'dayjs';
/**
 * 用来转换数据字典
 * @param value  用来转换的值
 * @param dictionary String  数据字典中具体的字典
 * @param type String('key' 或者 'value')  判断转换的值是 key(用key找value) 还是 value(用value找key)
 */
export const formatDictionary = function(value, dictionary, type = 'key') {
  const allDictionary = JSON.parse(window.localStorage.getItem('dictionary'));
  if (type === 'key') {
    return allDictionary[dictionary][value];
  } else if (type === 'value') {
    for (let key in allDictionary[dictionary]) {
      if (allDictionary[dictionary][key] === value) {
        return key;
      }
    }
  }
};

/**
 * 时间格式化
 * @param value
 * @param fmtstring 时间格式 例'YYYY-MM-DD'
 * @returns {string}
 */
export const formatTime = function(value, fmtstring) {
  return !value ? '' : dayjs(value).format(fmtstring);
};

// 金钱格式化
export const formatMoney = function(value, flag = '￥') {
  if (isNaN(value)) return value
  if (!value && value !== '0' && value !== 0) {
    return '';
  } else if (value === '-') {
    return value;
  } else {
    const valList = Number(value).toFixed(2).split('.')
    const decimal = valList[1];
    const integer = valList[0];
    return flag + (integer.toLocaleString('en-US') + '.' + decimal);
  }
};

// 数字千分位
export const formatNum = function(value) {
  if (!value && value !== '0' && value !== 0) {
    return '';
  } else if (value === '-') {
    return value;
  } else {
    return (Math.floor(value).toLocaleString('en-US'));
  }
};

// 显示缺省'-'
export const formatEmpty = function(value) {
  if (!value && value !== '0' && value !== 0) {
    return '-';
  } else {
    return value;
  }
};

// 保留n位小数
export const formatDecimal = (value, len = 2) => {
  if (value || value == '0') {
    return Number(value).toFixed(len);
  } else {
    return value;
  }
};

export default {
  dateFormat: formatTime,
  moneyFormat: formatMoney,
  dicFormat: formatDictionary,
  numFormat: formatNum,
  emptyFormat: formatEmpty,
  decimalFormat: formatDecimal
}
