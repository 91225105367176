import { noReadNotify } from '@/api/notify'

const state = {
  count: 0,
}

const mutations = {
  SET_COUNT: (state, count) => {
    state.count = count
  }
}

const actions = {
  // 获取未读消息数量
  async getNoReadNotify({ commit }) {
    const { data } = await noReadNotify()
    commit('SET_COUNT', data)
  }
}


export default {
  state,
  mutations,
  actions,
}