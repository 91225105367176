<template>
  <div class="sidebar-container-inner" @mouseleave="isShowSubMenuTemp = false">
    <div class="left-sideBar">
      <div class="logo-container">
        <img src="@/assets/logo.png" alt class="logo" />
      </div>
      <el-scrollbar class="scrollbar-container el-scrollbar-vertical" wrap-class="scrollbar-wrapper">
        <el-menu
          :default-active="activeMenu"
          :text-color="variables.menuText"
          :unique-opened="false"
          :active-text-color="variables.menuActiveText"
          mode="vertical"
          @select="selectLeftMenu"
          @mouseover.native="showSubMenuTemp"
        >
          <el-menu-item
            v-for="menu in menus"
            :key="menu.path"
            :index="menu.path"
            :class="activeMenu.split('/')[1] == menu.path.split('/')[1] ? 'is-active' : ''">
            {{menu.meta.title}}
          </el-menu-item>
        </el-menu>
      </el-scrollbar>
    </div>

    <el-scrollbar class="scrollbar-temp-container el-scrollbar-vertical" v-show="isShowSubMenuTemp" wrap-class="scrollbar-wrapper">
      <div class="right-sideBar subMenuTemp-sideBar">
        <div class="menuTitle">{{ menuTitleTemp }}</div>
        <el-menu
          :default-active="activeMenu"
          :text-color="variables.subMenuActiveText"
          :unique-opened="false"
          :active-text-color="variables.subMenuActiveText"
          :default-openeds="defaultTempOpens"
          mode="vertical"
          @select="selectTempMenu"
        >
          <sidebar-item
            v-for="menu in subMenuTemp"
            :key="menu.path"
            :item="menu"
            :base-path="menu.path"
          />
        </el-menu>
      </div>
    </el-scrollbar>

    <el-scrollbar class="scrollbar-full-container el-scrollbar-vertical"  v-show="rightMenu && isShowRightMenu" wrap-class="scrollbar-wrapper">
      <div class="right-sideBar">
        <div class="menuTitle">{{ menuTitle }}</div>
        <el-menu
          :default-active="activeMenu"
          :text-color="variables.subMenuActiveText"
          :unique-opened="false"
          :active-text-color="variables.subMenuActiveText"
          :default-openeds="defaultOpens"
          mode="vertical"
        >
          <sidebar-item
            v-for="menu in rightMenu"
            :key="menu.path"
            :item="menu"
            :base-path="menu.path"
          />
        </el-menu>
      </div>
    </el-scrollbar>
  </div>
</template>

<script>
  import { mapGetters } from 'vuex'
  import SidebarItem from './SidebarItem'
  import variables from '@/styles/variables.scss'

  export default {
    components: { SidebarItem },
    data() {
      return {
        timer: null, // 定时器
        isShowSubMenuTemp: false,
        subMenuTemp: null,
        menuTitleTemp: null,
        menuTitle: null,
      }
    },
    computed: {
      ...mapGetters([
        'sidebarCollapse',
        'userData',
        'menus',
        'rightMenu',
        'isShowRightMenu'
      ]),
      activeMenu() {
        const route = this.$route
        const { path } = route
        return path
      },
      variables() {
        return variables
      },
      defaultTempOpens() {
        if (this.subMenuTemp && this.subMenuTemp.length > 0) {
          let list = this.subMenuTemp.map((item) => {
            return item.path;
          })
          return list
        } else {
          return []
        }
      },
      defaultOpens() {
        if (this.rightMenu && this.rightMenu.length > 0) {
          let list = this.rightMenu.map((item) => {
            return item.path;
          })
          return list
        } else {
          return []
        }
      }
    },
    methods: {
      selectLeftMenu(pagePath, path) {
        // 找到当前路由对象，设置上边导航高亮,然后递归找到权限子路由的第一个页面
        let currentPageObj = this.menus.find(item => {
          if( item.path == pagePath ) {
            this.menuTitle = item.meta.title;
            return item;
          }
        });
        this.$store.dispatch('setRightMenu', currentPageObj.children || []);
        while(currentPageObj && currentPageObj.children) {
          currentPageObj = currentPageObj.children[0];
        }
        this.isShowSubMenuTemp = false;
        this.$router.push(currentPageObj.path);
      },
      showSubMenuTemp(event) {
        if (event.target.innerHTML.trim() == '首页') {
          this.isShowSubMenuTemp = false;
          return;
        }
        this.isShowSubMenuTemp = true;
        if(this.timer) {
          clearInterval(this.timer)
        }
        this.timer = setTimeout(() => {
          this.menus.some(item => {
            if (item.meta.title == event.target.innerHTML.trim()) {
              this.menuTitleTemp = item.meta.title;
              this.subMenuTemp = item.children;
            }
          })
        }, 200)
      },
      selectTempMenu() {
        this.$store.dispatch('setRightMenu', this.subMenuTemp || []);
        this.menuTitle = this.menuTitleTemp;
      }
    }
  }
</script>

<style lang="scss" scoped>
  .sidebar-container-inner {
    .left-sideBar {
      position: relative;
      width: 92px;
      background: $leftSideBar_color;
      .el-menu {
        width: 100%;
        background: transparent;
        border: 0;
        color: #fff;
      }
      .el-menu-item {
        height: 40px;
        line-height: 40px;
        text-align: center;
        &:hover {
          background: $menuHover;
        }
        &.is-active {
          background: $menuActiveText;
          color: #000 !important;
        }
      }
    }

    .menuTitle {
      height: 50px;
      line-height: 50px;
      text-align: center;
      font-size: 16px;
      border-bottom: 1px solid $borderColor;
    }

    .scrollbar-container {
      position: absolute;
      top: 80px;
      left: 0;
      right: 0;
      bottom: 0;
    }
    .right-sideBar {
      width: 150px !important;
      border-right: 1px solid #eee;
      .el-menu {
        height: 100%;
        border-right: 0;
      }
      ::v-deep .el-menu-item {
        width: 150px !important;
        min-width: auto;
      }
    }
    .scrollbar-temp-container {
      position: absolute;
      left: 92px;
      top: 0;
      bottom: 0;
      z-index: 99;
    }
  }
  .logo-container {
    position: absolute;
    top: 0;
    width: 100%;
    .logo {
      width: 100%;
      padding: 20px 20px 10px;
      box-sizing: border-box;
    }
  }
</style>
