import axios from 'axios'
import {Message, MessageBox} from 'element-ui'
import store from '@/store'
import {getToken, removeToken} from '@/utils/loginToken'
import { removeKey } from "@/utils/publicKey"
import router from '../router'
let messageLock = true;

// create an axios instance
const service = axios.create({
  baseURL: process.env.VUE_APP_BASE_API, // url = base url + request url
  // withCredentials: true, // send cookies when cross-domain requests
  timeout: 30000 // request timeout
})

// request interceptor
service.interceptors.request.use(
  config => {
    let token = getToken()
    if (token) {
      // ['X-Token'] is a custom headers key
      config.headers['X-Token'] = token
    }
    let tempData;
    if (config.data && !(config.data instanceof FormData)) {
      tempData = { ...config.data };
      for (let p in tempData) {
        if (tempData[p] === '') {
          delete tempData[p]
        }
      }
    }
    if (tempData) {
      return Object.assign({}, { ...config }, { data: tempData })
    } else {
      return config
    }
  },
  error => {
    console.log(error) // for debug
    return Promise.reject(error)
  }
)

// response interceptor
service.interceptors.response.use(
  response => {
    const res = response.data

    // if the custom code is not 20000, it is judged as an error.
    if (res.code === 20003 && messageLock) {
      messageLock = false;
      MessageBox.confirm('Token已失效，需要重新登录', 'token失效', {
        confirmButtonText: '重新登录',
        type: 'warning'
      }).then(() => {
        store.dispatch('setUserData', undefined).then(() => {
          removeToken();
          removeKey();
          router.push('/login');
        })
      }).finally(() => {
        messageLock = true;
      })
      return Promise.reject(new Error(res.message || 'Error'))
    } else if (res.code === 20004) {
      MessageBox.confirm('请先选择商户', {
        confirmButtonText: '确定',
        type: 'warning'
      }).then(() => {
        router.push('/selectMch');
      });
      return Promise.reject(new Error(res.message || 'Error'))
    }else if (res.code !== 20000) {
      res.message && Message({
        message: res.message,
        type: 'error',
        duration: 5 * 1000
      });
      return Promise.reject(new Error(res.message || 'Error'))
    } else {
      return res;
    }
  },
  error => {
    console.log('err' + error) // for debug
    Message({
      message: error.message,
      type: 'error',
      duration: 5 * 1000
    })
    return Promise.reject(error)
  }
)

export default service

export async function axiosRequest(config, option) {
  const response = await axios.request({
    baseURL: process.env.VUE_APP_BASE_API,
    url: config.url,
    method: config.method,
    params: config.params,
    data: config.data,
    headers: {
      'X-Token': getToken()
    }
  });
  const res = response.data;
  const { code, message } = res;
  if (code !== 20000) {
    if (!option.silence) {
      Message({
        message,
        type: 'error',
        duration: 5 * 1000
      })
    }
    return res
  } else {
    return res;
  }
}
