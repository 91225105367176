<template>
  <div class="navbar">
    <hamburger
      :is-active="!sidebarCollapse"
      class="hamburger-container"
      @toggleClick="toggleSideBar"
    />

    <breadcrumb class="breadcrumb-container" />

    <div class="right-menu">
      <router-link to="/notify/list">
        <el-badge
          :value="notify.count"
          class="navbar-notify-icon"
        >
          <i
            class="el-icon-bell"
            style="font-size: 20px;"
          ></i>
        </el-badge>
      </router-link>

      <el-dropdown
        class="avatar-container"
        trigger="click"
      >
        <div
          class="el-dropdown-link"
          style="cursor: pointer;"
        > {{ (userData && userData.name) || '' }}<i class="el-icon-arrow-down el-icon--right" /></div>
        <el-dropdown-menu
          slot="dropdown"
          style="width:150px;"
        >
          <router-link to="/">
            <el-dropdown-item>返回首页</el-dropdown-item>
          </router-link>
          <router-link to="/selectMch">
            <el-dropdown-item>切换商户</el-dropdown-item>
          </router-link>
          <router-link to="/accountManagement">
            <el-dropdown-item>个人账号管理</el-dropdown-item>
          </router-link>
          <el-dropdown-item divided>
            <span
              style="display:block;"
              @click="logout"
            >退出登录</span>
          </el-dropdown-item>
        </el-dropdown-menu>
      </el-dropdown>
    </div>
  </div>
</template>

<script>
  import { mapGetters } from 'vuex'
  import Breadcrumb from '@/components/Breadcrumb'
  import Hamburger from '@/components/Hamburger'
  import { removeToken } from "@/utils/loginToken"
  import { removeKey } from "@/utils/publicKey"
  export default {
    components: {
      Breadcrumb,
      Hamburger
    },
    computed: {
      ...mapGetters([
        'sidebarCollapse',
        'userData',
        'notify'
      ])
    },
    methods: {
      toggleSideBar() {
        this.$store.dispatch('toggleSideBar', !this.sidebarCollapse)
      },
      async logout() {
        await this.$store.dispatch('setUserData', undefined)
        removeKey();
        removeToken();
        this.$router.push(`/login?redirect=${this.$route.fullPath}`)
      }
    }
  }
</script>

<style lang="scss" scoped>
  .navbar {
    height: 50px;
    overflow: hidden;
    position: relative;
    background: #fff;
    box-shadow: 0 1px 4px rgba(0, 21, 41, 0.08);

    .hamburger-container {
      line-height: 46px;
      height: 100%;
      float: left;
      cursor: pointer;
      transition: background 0.3s;
      -webkit-tap-highlight-color: transparent;

      &:hover {
        background: rgba(0, 0, 0, 0.025);
      }
    }

    .breadcrumb-container {
      float: left;
    }

    .right-menu {
      float: right;
      height: 100%;
      line-height: 50px;
      margin-right: 20px;
    }

    .navbar-notify-icon {
      margin: 10px 20px;
      line-height: normal;
      border-radius: 20px;
      cursor: pointer;
    }
  }
</style>
